.loading_big_div {
	height: 95vh;
    align-items: center;
    display: flex;
    justify-content: center;
}

.loader {
	-ms-transform: translate(-50%,-50%);
	transform: translate(-50%,-50%);

	border: 16px solid #f4f2f0; 
	border-top: 16px solid #7899a8;
	border-radius: 50%;
	width: 7.5vmax;
	height: 7.5vmax;
	animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
