/* All font styles needed: 
story-h2 (Big Orange Text)
story-h3 (Gray Text)
story-download-text (dark blue text)
 */
#coin-pile-page{
  // overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  width: 100%;
	// height: 100%;
	padding-top: 7.5vmax;
	padding-bottom: 7.5vmax;
  
  ol {
    list-style: none;
    counter-reset: item;
    text-indent: -3.5em;
    margin-left: 3.5em;
  }
  li {
    counter-increment: item;
  }
  li:before {
    content: counter(item)".";
    color: #b85828;
    width: 1em;
    text-align: center;
    display: inline-block;
    font-weight: 600;
  }
// $tooSmallForPileScreenSize: 75em;
#coin-sort-wrapper {
  position: relative;
  padding-bottom: 33%; //I dont know about this, need re-do later
}

#coin-sort-spacer {
  width: 100%;
  height: 1em;
}

#coin-sort-title {
  position: relative;
  z-index: 50;
  pointer-events: none;
}

/* COIN SORT MENU */

#coin-sort-options-wrapper {
  position: relative;
  display: flex;
  pointer-events: none;
}

#coin-sort-options {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  max-width: 97%;

  padding-left: 10px;
  padding-right: 10px;
  margin: auto;
  left: 0;
  right: 0;

  background-color: white;
  border-radius: 5px;
  
  pointer-events: auto;
}

.coin-sort-menu-vr {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
}

.coin-sort-menu-vr-content {
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  height: 90%;
}

.coin-sort-options-icons-div {
  margin-top: 10px;

  height:100%;
  display: grid;
  // grid-template-columns: 1fr;
  // grid-template-rows: repeat(2, 1fr);

  justify-content: center;
  align-items: center;

  cursor: pointer;
  transition: 0.3s;
}

.coin-sort-options-icons-div:hover .coin-sort-options-icon,
.coin-sort-options-icons-div:hover .coin-sort-options-icon-text
{
  color: #183848;
}

.coin-sort-options-icon {
  color: #7899A8;
  font-size: 3.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  // grid-area: 1 / 1 / 2 / 2;
  transition: 0.3s;
}

.coin-sort-options-icon-text {
  position: relative;
  top: -10px;
  color: #7899A8;
  display: flex;
  justify-content: center;
  align-items: center;
  // grid-area: 2 / 1 / 3 / 2; 
  transition: 0.3s;

  font-family: $syrios-garamond-premier-pro;
  font-style: $syrios-font-style-normal;
  font-weight: $syrios-font-weight-regular;
  color: #7899A8;
  font-size: 1.2em;
}

 
// .coin-sort-options {
//   margin-top: 10px;
//   margin-left: 5px;
//   margin-right: 5px;
// }

/*********************** COIN SORT DROPDOWN **************************/
.coin-sort-dropdown-outermost-div {
  position: relative;
}

.coin-sort-dropdown-title-text {
  font-family: $syrios-font-family-garamond-premier-pro;
  font-style: $syrios-font-style-normal;
  font-weight: $syrios-font-weight-regular;
  color: $syrios-color-737271;

  margin-bottom: 0px;
  font-size: 2em;
}

.coin-sort-info-icon {
  color: #7899A8;
  transition: 0.3s;

  cursor: pointer;
}

.coin-sort-info-icon:hover {
  color: #183848;
}

.coin-sort-dropdown-bar {
  display: flex;
  min-width: 100px;

  margin-bottom: 13px;

  border: thin solid #7899A8;
  border-radius: 5px;
  cursor: pointer;

  font-size: 1.1em;

  align-items: center;

  transition: 0.3s;
}

.coin-sort-dropdown-bar:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.coin-sort-dropdow-bar-text {
  font-size: 1.4em;
  margin: 5px;

  font-family: $syrios-garamond-premier-pro;
  font-style: $syrios-font-style-normal;
  font-weight: $syrios-font-weight-regular;
  color: #7899A8;
}

.coin-sort-dropdown-arrow {
  position: relative;
  margin: 5px;
  margin-left: auto;

  border: solid #183848;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;

  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}


.coin-sort-dropdown {
  position: absolute;
  width: 250px;
  top: 0px;

  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 10px gray;

  transition: 0.17s;
}

.coin-sort-dropdown-items {
  margin: 15px;
  margin-left: 0px;
  margin-right: 0px;
}

.coin-sort-dropdown-item:hover {
  background-color: #7899A8;

  transition: 0.3s;

  color: white;

  cursor: pointer;
}

.coin-sort-dropdown-item:hover p {
  transition: 0.3s;

  color: white;
}

.coin-sort-dropdown-item:hover hr {
  transition: 0.3s;
  border: thin solid #7899A8;
}

.coin-sort-dropdown-item-text {
  font-family: $syrios-garamond-premier-pro-caption;
  font-style: $syrios-font-style-normal;
  font-weight: $syrios-font-weight-normal;
  font-size: 1.4em;
  line-height: $syrios-line-spacing-15;
  letter-spacing: $syrios-character-spacing--10;
  color: $syrios-color-183848;
  padding-top: 5px;
  margin-bottom: -15px;
  margin-left: 15px;
}

.coin-sort-dropdown-item-line-spacer {
  border: thin solid #7899A8;
  margin-left: 15px;
  margin-right: 15px;
}

.coin-sort-clear-button {
  margin-top: -10px;

  font-family: $syrios-garamond-premier-pro-caption;
  font-style: $syrios-font-style-normal;
  font-weight: $syrios-font-weight-normal;
  color: #a85828;

  cursor: pointer;

  transition: 0.3s;
}

.coin-sort-clear-button:hover {
  color: #5b2f16;
}

/****************** COIN SORT PILE ******************/
.coin-sort-pile-wrapper {
  // position: fixed;
  position: absolute;

  top: $footerHeight;
  display: flex;
  height: calc(100vh - 2 * $footerHeight);
  width: 100vw;

  transition: 0.1s;
  flex-grow: 1;
}

.coin-sort-pile {
  position: relative;
  transition: 0.1s;
}

// @media only screen and (max-width: $tooSmallForPileScreenSize) {
//   .coin-sort-pile {
//     display: none;
//   }

//   .coin-sort-wrapper {
//     display: block;
//   }
// }
.coin-sort-pile-coin {
  position: absolute;
}

// .coin-sort-pile-coin-image {
  // cursor: pointer;
  // cursor: move;
  // position: absolute;
  // object-fit: cover;

  // -webkit-user-drag: none; 

  // -khtml-user-drag: none; 
  // -o-user-drag: none; 
  // user-select: none;

  // -moz-user-drag: none; 


// }

.coin-sort-pile-coin-image {
  position: relative;
  object-fit: cover;

  cursor: pointer;

  transition: 0.1s;
}

.coin-sort-pile-coin-image:hover {
  z-index: 10;
  transform: scale(3);
}
  
}

