/* Character Styles */
// @import '../0-base/_base-dir.scss';
@use "sass:math";

@import '../1-themes/_themes-dir.scss'; //color font
// @import '../2-foundations/_foundations-dir.scss'; //spacing

.landing-buttons-text {
	text-align: center;
	text-decoration: none !important;
  
  font-family: $syrios-garamond-premier-pro;
  font-weight: $syrios-font-weight-semi-bold;
  font-size: $syrios-font-size-32;
  line-height: $syrios-line-spacing-30;
  letter-spacing: $syrios-character-spacing-0;
  color: $syrios-color-ffffff;

  // @media screen and (max-width: 1440px) {
  //   font-size: $syrios-font-size-30 * 0.75 ;
  //   line-height: $syrios-line-spacing-30 * 0.75;

  // }
}

.quote-text {
  font-family: $syrios-garamond-premier-pro-caption;
  font-weight: $syrios-font-weight-regular;
  font-size: $syrios-font-size-40;
  line-height: $syrios-line-spacing-45;
  letter-spacing: $syrios-character-spacing-0;
  color: #486678;



  // @media screen and (max-width: 1440px) {
  //   font-size: $syrios-font-size-40 * 0.75 ;
  //   line-height: $syrios-line-spacing-45 * 0.75;

  // }
}
.quote-credit {
  font-family: $syrios-font-family-garamond-premier-pro;
  font-weight: $syrios-font-weight-regular;
  font-size: $syrios-font-size-20;
  line-height: $syrios-line-spacing-30;
  letter-spacing: $syrios-character-spacing--05;
  color: #737271;
}

.story-caption {
  font-family: $syrios-garamond-premier-pro;
  font-weight: $syrios-font-weight-regular;
  font-size: $syrios-font-size-18;
  line-height: $syrios-line-spacing-20;
  letter-spacing: $syrios-character-spacing-0;
  color: #737271;
}

.story-icon{
  font-family: $syrios-font-family-syrios-entypo-subset;
  color: #7899a8;
  left: -0.2em;
}

.scale-icon{
  position:fixed;
  z-index: 3;
  font-size: 5vmax;
  cursor: pointer;
}

.flip-icon{
  // position:fixed;
  // z-index: 3;
  font-size: 5vmax;
  cursor: pointer;
}
.scale-text{
  position:fixed;
  z-index: 3;
  margin-top: 6.25vmax;

  .scale-text-front{
    display: block;
    opacity: 1;
    transition: 0.3s;
  }
  .scale-text-back{
    display: none;
    opacity: 0;
    transition: 0.3s;
  }
}

.story-text {
  font-family: $syrios-garamond-premier-pro;
  font-weight: $syrios-font-weight-regular;
  font-size: $syrios-font-size-24;
  line-height: $syrios-line-spacing-30;
  letter-spacing: $syrios-character-spacing--05;
  color: #737271;

  // @media screen and (max-width: 1440px) {
  //   font-size: $syrios-font-size-24 * 0.75 ;
  //   line-height: $syrios-line-spacing-30 * 0.75;
  // }
}

.story-text-bigger {
  font-family: $syrios-garamond-premier-pro;
  font-weight: $syrios-font-weight-regular;
  font-size: 1.5vmax;
  line-height: 2.25vmax;
  color: #737271;

  // @media screen and (max-width: 1440px) {
  //   font-size: $syrios-font-size-24 * 0.75 ;
  //   line-height: $syrios-line-spacing-30 * 0.75;
  // }
}

.story-h1 {
  font-family: $syrios-garamond-premier-pro-display;
  font-weight: 300;
  font-size: $syrios-font-size-144;
  // line-height: $syrios-line-spacing-75;
  letter-spacing: $syrios-character-spacing-0;
  color: #486678;

}

.story-h2 {
  font-family: $syrios-garamond-premier-pro-display;
  font-weight: $syrios-font-weight-light;
  font-size: $syrios-font-size-64;
  line-height: 1em;
  letter-spacing: $syrios-character-spacing-0;
  color: #a85828;
  text-align: center;
}

.story-h3 {
  font-family: $syrios-garamond-premier-pro;
  font-weight: $syrios-font-weight-regular;
  font-size: $syrios-font-size-40;
  line-height: $syrios-line-spacing-45;
  color: #737271;
}

.story-h3-blue {
  font-family: $syrios-garamond-premier-pro;
  font-weight: $syrios-font-weight-regular;
  font-size: $syrios-font-size-40;
  line-height: $syrios-line-spacing-45;
  letter-spacing: $syrios-character-spacing-0;
  color: #486678;
}

.story-h4 {
  font-family: $syrios-garamond-premier-pro-subhead;
  font-weight: $syrios-font-weight-semi-bold;
  font-size: $syrios-font-size-20;
  line-height: $syrios-line-spacing-25;
  letter-spacing: $syrios-character-spacing-0;
  color: #a86818;
  text-transform: $syrios-text-transform-uppercase;

}



// .story-nav-text {
//   font-family: $syrios-garamond-premier-pro-caption;
//   font-style: $syrios-font-style-normal;
//   font-weight: $syrios-font-weight-medium;
//   font-size: $syrios-font-size-14;
//   line-height: $syrios-line-spacing-17;
//   letter-spacing: $syrios-character-spacing--80;
//   color: #ffffff;
//   text-transform: $syrios-text-transform-uppercase;

// }

.landing-text{
  font-family: $syrios-garamond-premier-pro;
  font-size: $syrios-font-size-24;
  line-height: $syrios-line-spacing-30;
  letter-spacing: $syrios-character-spacing-0;
  color: #486678;
}

.select-story-text {
  font-family: $syrios-garamond-premier-pro-subhead;
  font-weight: $syrios-font-weight-semi-bold;
  font-size: $syrios-font-size-24;
  line-height: $syrios-line-spacing-25;
  letter-spacing: $syrios-character-spacing--20;
  color: #a86818;
}

.references-text {
  font-family: $syrios-garamond-premier-pro-caption;
  font-weight: $syrios-font-weight-normal;
  font-size: $syrios-font-size-18;
  line-height: $syrios-line-spacing-15;
  letter-spacing: $syrios-character-spacing--10;
  color: #183848;

  a {
    color: #183848;
  }
  a[target='_blank']:after {
    content: '';
  }
}
.references-h3 {
  font-family: $syrios-garamond-premier-pro;
  font-weight: $syrios-font-weight-semi-bold;
  font-size: $syrios-font-size-32;
  line-height: $syrios-line-spacing-30;
  color: #ffffff;

  // @media screen and (max-width: 1440px) {
  //   font-size:  $syrios-font-size-32 * 0.75 ;
  //   line-height: $syrios-line-spacing-30 * 0.75;
  // }  
}

.icon-caption{
  font-family: $syrios-garamond-premier-pro;
  font-weight: $syrios-font-weight-regular;
  font-size: $syrios-font-size-20;
  line-height: $syrios-line-spacing-20;
  letter-spacing: $syrios-character-spacing--50;
  color: #a86818;

  // @media screen and (max-width: 1440px) {
  //   font-size:  $syrios-font-size-20 * 0.75 ;
  //   line-height: $syrios-line-spacing-20 * 0.75;
  //   letter-spacing: $syrios-character-spacing--50 * 0.75 ;
  // }  
}

.footer-text {
  text-align: center;
  opacity: 1;
  transition: 0.5s;
  
  font-family: $syrios-garamond-premier-pro-caption;
  font-weight: $syrios-font-weight-medium;
  font-size: $syrios-font-size-18;
  line-height: $syrios-line-spacing-17;
  letter-spacing: $syrios-character-spacing--80;
  color: $syrios-color-ffffff;
  text-transform: $syrios-text-transform-uppercase;
  &:hover {
    color: #b8ccd8;
    cursor: pointer;
  }

  // @media screen and (max-width: 1440px) {
  //   font-size: $syrios-font-size-18 * 0.75;
  //   line-height: $syrios-line-spacing-17 * 0.75;
  //   letter-spacing: $syrios-character-spacing--80 * 0.75;
  // }  
}

.navbar-text {
	// text-align: center;
	opacity: 1;
  transition: 0.3s;
  
  font-family: $syrios-garamond-premier-pro-caption;
  font-weight: $syrios-font-weight-medium;
  font-size: $syrios-font-size-20;
  line-height: $syrios-line-spacing-17;
  // letter-spacing: $syrios-character-spacing--80;
  color: #ffffff;
  &:hover {
    color: #B8CCD8;
    cursor: pointer;
      transition: 0.3s;
  }
  // @media screen and (max-width: 1440px) {
  //   font-size:  $syrios-font-size-18 * 0.75 ;
  //   line-height: $syrios-line-spacing-17 * 0.75;
  //   letter-spacing: $syrios-character-spacing--80 * 0.75;
  // }  
}