
#aboutus-page {
	-webkit-overflow-scrolling: touch;
  padding: 5% 10%;
	height: 100%;
}
.aboutus-des{
  // width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.aboutus-logo{
  width: 20%;
  align-items: center;

}
.aboutus-description{
  width: 70%;
}

// .aboutus-project_director{
//   font-size: calc(16px + 1vw);
//   display: flex;
//   width: 90%;  
// }


.aboutus-avatar{
  border-radius: 50%;
  padding: 0.75vmax;
  background-color: white;
  // width: 100%;

}
.aboutus-avatar__image{
  border-radius: 50%;
  height: 100%;
}
.aboutTable {
  font-size: calc(16px + 1vw);
  display: flex;
  // margin: 2em auto;
  // margin-left: 10%;
}

.aboutTable_tabs {
  width: 30%;

}

.aboutTable_tab {
  // width: 100%;
  height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #737271;
  clip-path: polygon(10% 0%,100% 0,100% 100%, 10% 100%, 0% 90%, 0% 10%);
  background-color: #ffffff;
  margin-bottom: 1.75em;
}

.aboutTable_tab:hover {
  background-color: rgba(184,204,216,0.5);
  cursor: pointer;
}

.aboutTable_tab--active {
  background-color: rgba(184,204,216,0.5);
  pointer-events: none;
}

.aboutTable_entries { 
  width: 70%;
  max-height: 30em;
  flex-direction: row;
  padding-top: 0.2em;
  overflow-y: scroll;
  background-color: rgba(184,204,216,0.5);
  border-radius: 0px 15px 15px 0px;
}

.aboutTable_entries > * {
  padding-left: 0.4em;
}

.aboutTable_entry {
  display: flex;
  align-items: center;
}

.aboutTable-student_leads {
  padding: .5em;
  display: flex;
  align-items: center;
}
.aboutTable-collaborators {
  // padding: .5em;
  display: flex;
  align-items: center;
}
.aboutTable-humanities_advisors {
  padding: .5em;
  display: flex;
  align-items: center;
}
.aboutTable-grants_funding {
  // padding: .5em;
  display: flex;
  align-items: center;

  span{
    margin-left: 1em;
  }
  a{
    text-decoration:underline
  }
}

