#CoinInfoPage{
    -webkit-overflow-scrolling: touch;
	height: 100%;
	padding:5% 10%;

	.section1{
		.title{
			left: 0px;
			top: 430px;

			font-style: normal;
			font-weight: 400;
			font-size: 84px;
			line-height: 102px;
			text-align: center;

			color: #313029;
		}
		.year{
			left: 0px;
			top: 430px;

			font-style: italic;
			font-weight: 400;
			font-size: 70px;
			line-height: 85px;
			text-align: center;

			color: #737271;
		}
		.basic-info{
			display: grid;
			width: 100%;
			grid-template-columns: 1fr 1fr;
			gap: 10px;
			
			.item{
				h1{
					font-style: normal;
					font-weight: 500;
					font-size: 50px;
					line-height: 60px;
					text-align: center;
					color: #486678;
				}
				h2{
					font-style: normal;
					font-weight: 400;
					font-size: 42px;
					line-height: 60px;
					text-align: center;
					color: #486678;
				}
			}
		}
	}
	.section2{
		display: flex;
		
		.left{
			flex: 1;

		}
		.right{
			flex: 1;

			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 10px;

			.item{
				h1{
					text-align: left;
					font-style: normal;
					font-weight: 500;
					font-size: 48px;
					line-height: 54px;
					color: #737271;
				}
				p{
					font-style: normal;
					font-weight: 700;
					font-size: 40px;
					line-height: 67px;
					color: #D8B848;
				}
			}
		}
	}
	.section2-bottom{
		display: flex;
        justify-content: space-between;
		flex-direction: column;
		align-items: center;
        padding: 20px;
		
		.item{
			font-style: normal;
			font-weight: 300;
			font-size: 24px;
			line-height: 29px;
			text-align: center;

			color: #737271;

		}

		.BackButton{
			margin-top: 30px;
			width: 30%;
			text-transform: capitalize;
			padding: 0.5em 0.75em;
			border-radius: 15px;
	
			font-weight: 700;
			font-size: 3rem;
			line-height: 36px;
			text-align: center;
	
			color: #7899a8;
			background: #ffffff 0% 0% no-repeat padding-box;
			border: 2px solid #7899a8;
			opacity: 1;
			transition: 0.5s;
			
			
			&:hover {
				color: #ffffff;
				background: #7899a8 0% 0% no-repeat padding-box;
			}
			
		}

	}
}