
#Timeline-page{
	-webkit-overflow-scrolling: touch;
	width: 100%;
	height: 100%;
	padding-top: 7.5vmax;
  .coin-image {
    cursor: pointer;
    border-radius: 50%;
  }
  
  .event {
    cursor: pointer;
  }
  
  .timeline-info-dynamic-text {
    font-size: 16px;
  }
  
}

.timeline-legend{
  display: block;
  right: 5vmax;
  bottom: 5vmax;
  position:absolute;
  z-index: 1;

  background-color: rgba(255,255,255,0.25);
  padding: 1vmax;
  border-radius: 15px;
  
}

.tooltip-inner{
  padding:0.25rem 2rem;
  // background-color: rgba(0,0,0,0.9);
  font-family: $syrios-garamond-premier-pro;
  font-style: $syrios-font-style-normal;
  font-weight: $syrios-font-weight-regular;
  font-size: $syrios-font-size-24;
  line-height: $syrios-line-spacing-45;
  letter-spacing: 1px;
  // color:#737271;
}
.sticky-sections {

  width: 100%;
  display: flex;
  text-align:center;
  font-size: 8rem;
  font-weight: 100;

  position: sticky;
  top: 93vh;
  z-index: 10;
  
}
#time-west{
  flex-grow: 3;
}
#time-antioch{
  flex-grow: 5;
}
#time-east{
  flex-grow: 4;
}

