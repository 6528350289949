#video-library{
    -webkit-overflow-scrolling: touch;
	min-height: 95vh;
    padding: 5% 10%;

    .image-icon{
        // font-size:15vmax;
        border-style: double;
        border-color:#7899a8;
        border-radius: 50%;
        font-family: $syrios-font-family-syrios-entypo-subset;
        color: rgba(255,255,255,1);
        background-color: #7899a8;
    }
}    
