/* The blue box at the top of the screen and its dimensions are defined here */

#navbar {
	background: #183848;
	padding: 3rem;
}

/* General navbar styling */
.navbar-dark .navbar-nav .nav-link {
	color: white;
	margin: 0 1rem ;
	&:hover{
		color: #B8CCD8 ;
	}
}

.dropdown-menu {
	background: url("../../assets/dropdown.png");
	background-color: #183848;
	background-size: cover;
	border-radius: 0px 0px 15px 15px;
	font-family:  $syrios-font-family-garamond-premier-pro;
}

.dropdown .dropdown-menu {
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    display: block;
    opacity: 0;
	margin-top: 3rem;
	left: 2rem;
}

.dropdown.show .dropdown-menu { /* For Bootstrap 4, use .dropdown.show instead of .dropdown.open */
    opacity: 1;
}
.dropdown-item {
	color: white ;
	padding: 2.5rem 3rem ;
	transition: 0.3s;

	&:hover {
		background: none;
		color: #B8CCD8;
	}
}