
#footer {
  position: fixed;
  background: #183848;
  bottom: 0px;
  width: 100%;
  // height:  3.75vmax;
  // opacity: 1;

  // z-index: 99998;

  #footer-logo {
    position: absolute;
    left: 0.5vmax;
    margin: 0;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  
  #footer-links {
    height: 3.125vmax;

    // position: absolute;
    // margin: 0;
    // top: 50%;
    // -ms-transform: translateY(-50%);
    // transform: translateY(-50%);
    // width: 30em;
  }
  
  /* sets width of footer links whenever the size gets to a certain size to make it more responsive */

  
  /* All text for footer should be this format */

  button{
    border: none;
    cursor: pointer;
    display: inline-block;
    background-color: #183848;
    align-items: center;
    color:#FFFFFF
  }
  
  #creditsAndReferences{
    overflow-y: scroll;
    display: flex;
    padding: 0 10%;
  }
  .imageReference{
    flex: 1;
  }
  .otherReference{
    flex:1;
  }
  
  #credits-and-references-x-icon {
    position: absolute;
    right: -1.667vmax;
    top: -0.833vmax;
    font-size: 5vmax;
    color: white;
    cursor: pointer;
  
    transition: 0.3s;
  }
  
  #credits-and-references-x-icon:hover {
    color: #183848;
  }
  
  #credits-and-references-lists {
    height: 100%;
    width: 80%;
    overflow-y: hidden;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  
    font-family: $syrios-garamond-premier-pro;
    font-style: $syrios-font-style-normal;
    font-weight: $syrios-font-weight-regular;
    font-size: $syrios-font-size-20;
    letter-spacing: $syrios-character-spacing-0;
  }
  
  .credits-and-references-list-items p {
    font-family: $syrios-garamond-premier-pro;
    font-style: $syrios-font-style-normal;
    font-weight: $syrios-font-weight-regular;
    font-size: $syrios-font-size-24;
    line-height: $syrios-line-spacing-30;
    letter-spacing: $syrios-character-spacing-0;
    color: $syrios-color-183848;
    font-size: $syrios-font-size-16;
  }
  
  .credits-and-references-list-items p a {
    color: #183848;
    white-space: normal;
    width: 100%;
    height: auto;
    word-wrap: break-word;
    transition: 0.3s;
   
    font-family: $syrios-garamond-premier-pro;
    font-style: $syrios-font-style-normal;
    font-weight: $syrios-font-weight-regular;
    font-size: 16px;
    line-height: $syrios-line-spacing-30;
    letter-spacing: $syrios-character-spacing-0;
    color: $syrios-color-183848;
  }
  
  .credits-and-references-list-items p a:hover {
    color: #486678 !important;
  }
  
  .credits-and-references-list[title]::before {
    align-content: start;
    content: attr(title);
    /* then add some nice styling as needed, eg: */
    display: block;
    color: #ffffff;
    font-weight: bold;
    font-size: 2em;
  }
  
  @media only screen and (max-width: 767px) {
    .credits-and-references-list[title]::before {
      font-size: 1em;
    }
  
    .list-items p {
      font-size: 0.5em;
    }
  }
  
  .credits-and-references-list {
    width: 90% !important;
    counter-reset: item;
    height: 95%;
    margin: 1em;
    overflow: hidden;
  }
  
  .credits-and-references-list-item {
    display: block;
    margin-bottom: 0.5em;
    margin-left: 2em;
    font-size: 0.8em;
   
    font-family: $syrios-garamond-premier-pro;
    font-style: $syrios-font-style-normal;
    font-weight: $syrios-font-weight-regular;
    font-size: $syrios-font-size-24;
    line-height: $syrios-line-spacing-30;
    letter-spacing: $syrios-character-spacing-0;
    color: $syrios-color-183848;
  }
  
  .credits-and-references-list-item::before {
    display: inline-block;
    content: "[" counter(item) "]";
    counter-increment: item;
    width: 2em;
    margin-left: -2em;
    
    font-family: $syrios-garamond-premier-pro;
    font-style: $syrios-font-style-normal;
    font-weight: $syrios-font-weight-regular;
    font-size: $syrios-font-size-24;
    line-height: $syrios-line-spacing-30;
    letter-spacing: $syrios-character-spacing-0;
    color: $syrios-color-183848;
  }
  
  .credits-and-references-list-item-href {
    text-decoration: none;
    transition: 0.2s;
  }
  
  .credits-and-references-list-item-href:hover {
    color: #173847;
  }
}


.credits-and-references {

  background: url('../../assets/credits&references.jpg') !important;

  .x-button{
    position: absolute;
    right: 30px;
    background: transparent;
    border: none;
    font-size: 32px;
    margin-top:20px;

    font-family: $syrios-font-family-syrios-entypo-subset;
    color: #FFFFFF;
  }
}
.referenceContent{
  display: flex;
  justify-content: space-between;
}

#reference-content{
  // overflow-y: auto;
  max-height: max-content;
  padding-right: 2em;
}
