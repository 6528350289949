
#event-white-background {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: white;
  z-index: -100;

  transition: 0.6s;
  opacity: 0;
}

#event-info {
  width: 80vw;
  max-width: 800px;
}

#event-info-inner-div {
  margin: 30px;
}

#event-tags-title {
  font-family: $syrios-garamond-premier-pro;
  font-style: $syrios-font-style-normal;
  font-weight: $syrios-font-weight-regular;
  font-size: $syrios-font-size-20;
  line-height: $syrios-line-spacing-20;
  letter-spacing: $syrios-character-spacing-0;
  color: $syrios-color-737271;
  font-size: 14px;
  margin-bottom: 3px;
}

#event-tags {
  display: flex;
  gap: 15px;

  // margin-bottom: 30px;
}

.event-tag {
  margin-bottom: 10px;
  border-radius: 4000000px;
  width: fit-content;
  display: flex;
  align-items:center;
  justify-content:center;
}

.tag-text {
  font-family: $syrios-garamond-premier-pro-caption;
  font-style: $syrios-font-style-normal;
  font-weight: $syrios-font-weight-medium;
  font-size: $syrios-font-size-14;
  line-height: $syrios-line-spacing-17;
  letter-spacing: $syrios-character-spacing--80;
  color: $syrios-color-ffffff;
  text-transform: $syrios-text-transform-uppercase;
  margin: 10px 20px 10px 20px !important;
  margin: 5px;
  font-size: 20px;
}

#event-title {
  font-family: $syrios-garamond-premier-pro;
  font-style: $syrios-font-style-normal;
  font-weight: $syrios-font-weight-600;
  font-size: $syrios-font-size-40;
  line-height: $syrios-line-spacing-45;
  letter-spacing: $syrios-character-spacing-0;
  color: #183848;
  font-size: 32px;
}

#event-description {
  font-family: $syrios-garamond-premier-pro;
  font-style: $syrios-font-style-normal;
  font-weight: $syrios-font-weight-500;
  font-size: $syrios-font-size-40;
  letter-spacing: $syrios-character-spacing-0;
  color: #183848;
  font-size: 20px;
}

#event-description > div > p > a {
  font-family: $syrios-garamond-premier-pro-caption;
  font-style: $syrios-font-style-italic;
  font-weight: $syrios-font-weight-semi-bold;
  line-height: $syrios-line-spacing-45;
  letter-spacing: $syrios-character-spacing-0;
  color: #78a878;

  transition: 0.3s;
}

#event-description > div > p > a:hover {
  color: #487848;
}

#event-connections-background {
  margin-top: 30px;
  background-color: rgba(155, 155, 160, 0.3);
}

#event-connections {
  padding: 20px;
  font-family: $syrios-garamond-premier-pro;
  font-style: $syrios-font-style-normal;
  font-weight: $syrios-font-weight-regular;
  font-size: $syrios-font-size-20;
  line-height: $syrios-line-spacing-20;
  letter-spacing: $syrios-character-spacing-0;
  color: $syrios-color-737271;
}

#event-connections-text {
  font-family: $syrios-garamond-premier-pro;
  font-style: $syrios-font-style-normal;
  font-weight: $syrios-font-weight-semi-bold;
  font-size: $syrios-font-size-20;
  line-height: $syrios-line-spacing-20;
  letter-spacing: $syrios-character-spacing-0;
  color: $syrios-color-737271;
  margin-bottom: 0px;
  font-size:16px;
}

#event-connections-list {
  font-family: $syrios-garamond-premier-pro;
  font-style: $syrios-font-style-normal;
  font-weight: $syrios-font-weight-regular;
  font-size: $syrios-font-size-20;
  line-height: $syrios-line-spacing-20;
  letter-spacing: $syrios-character-spacing-0;
  color: $syrios-color-737271;
  margin-bottom: 0px;
  font-size:14px;
  font-size:14px;
}

.type-category p a {
  color: #173847;
  text-decoration: underline !important;
  transition: 0.2s;
}
.type-category p a:hover {
  color: #7899A8;
}
