
#download-page {
  -webkit-overflow-scrolling: touch;
	min-height: 95vh;
	padding: 5% 10%;
  
  .download-icon {
    font-size: 7vmax;
  }
}
#downloadNotes{
  line-height: $syrios-line-spacing-20;
  font-family: $syrios-font-family-garamond-premier-pro;
  font-style: $syrios-font-style-normal;
  font-weight: $syrios-font-weight-semi-bold;
  font-size: $syrios-font-size-20;
  color: #183848;
  text-align:	center;
  margin-bottom: 10%;
}
#downloadForm{
  background-color: rgba(184,204,216,0.35);
  border-radius: 15px;
  padding: 5% 10%;

  label {
    line-height: 1;
    text-align: left;
    display: block;
    color: #183848;
    font-size: 2.5rem;
    margin-bottom: 5%;
  }
  span{
    font-size: 2rem;
  }
  .formItem{
    margin-bottom: 5%;
  }
  .error-message{
    font-size: 1.5em;
    color: red;
  }
  input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    border: 1px solid white;
    padding: 10px 15px;
    margin-bottom: 10px;
    font-size: 2rem;
  }

  input[type="checkbox"]{
    display: inline-block;
    width: auto;
    border: 1px solid #7899a8;
    color: #183848;
    font-size: 2rem;
  }

  input[type="submit"] {
    background: #7899A8 0% 0% no-repeat padding-box;
    color: #ffffff;
    text-transform: uppercase;
    border: 1px solid #7899a8;
    border-radius: 15px;
    font-size: 2rem;
    padding: 5px 10px;

    &:hover{
      background: #ffffff 0% 0% no-repeat padding-box;
      color: #7899A8;
    }
  }
  .downloading{
    color: #183848;
    font-size: 3rem;
    text-align: center;

  }
}




