
.translucent-white-background {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.6);

  z-index: -1000;
  opacity: 0;

  transition: 0.1s;
}

.snow-white-background {
  background-color: snow;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  min-width: 2em;
  min-height: 2em;

  z-index: -1000;
  opacity: 0;

  box-shadow: 0px 0px 20px black;

  transition: 0.1s;
}

.white-pop-up-x-icon {
  font-size: 50px;
  color: #7899A8;
  cursor: pointer;

  position: absolute;
  top: 0px;
  right: -10px;
  z-index: 1000;

  transition: 0.3s;
}

.white-pop-up-x-icon:hover {
  color: #183848;
}

.white-pop-up-inner-padding {
  padding: 40px;
}
