.search{
    position: relative;

    .tag-content{
        font-size: 32px;
        width: 100%;
        // height: 400px;
        // position: absolute;
    
        background-color: #fff;
        z-index: 2;
        // border: 2px solid #737271;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 15px;
    
        ul{
            display: flex;
            flex-wrap: wrap;
            padding: 1rem;
            margin: 2rem 0rem;
            a{
                align-self: center;
            }
            li{
                color: #333;
                list-style: none;
                border-radius: 15px;
                background: #F2F2F2;
                margin:  1rem 0.75rem;
                padding:1rem 1.5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                span{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 20px;
                    width: 20px;
                    color: #808080;
                    margin-left: 8px;
                    font-size: 24px;
                    cursor: pointer;
                    border-radius: 50%;
                    background: #dfdfdf;
                    justify-content: center;
                }
              }
            input{
                flex: 1;
                padding: 5px;
                border: none;
                outline: none;
                font-size: 32px;

                margin:  1rem 0.75rem;
                padding:1rem 1.5rem;
              }
        }
    }
    .tag-options{
        background-color: #fff;
        width: 100%;
        font-size: 32px;
        border-radius: 15px;

        p{
            text-align: left;
            margin-left: 2%;
        }


        ul{
            display: flex;
            flex-wrap: wrap;
            padding: 1rem;
            margin: 2rem 0rem;
            width: 100%;
    
            li{
                color: #333;
                margin:  1rem 0.75rem;
                list-style: none;
                border-radius: 15px;
                background: #F2F2F2;
                padding:1rem 1.5rem;

                border: 1px solid #e3e1e1;
                cursor: pointer;

              }
        }
    }

}
