
#stories-page{
  -webkit-overflow-scrolling: touch;
	// height: 100vh;
  min-height: 95vh;    
  padding: 5% 10%;
  background:url('https://syrios.cs.uh.edu/uploads/Tyche_Antioch_3615c03897.png') right / cover no-repeat;
  mix-blend-mode: multiply;

  .select-story-div {
    // position:absolute;
    width: 10vmax;
    transition: 0.5s;
    &:hover{
      width: 12vmax;
    }
  }
}



.stories-background{
  // mix-blend-mode: overlay;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  background-blend-mode: lighten;

}

/******************** THIS IS FOR THE STORIES ****************/
#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  background: #183848 !important;
  transition: 0.5s;
}

//for each Story frame
.section {
  // background-size: cover, auto !important;
  // background-position: 50% 50%, 50% 50% !important;
  padding: 0px;
  /* background-blend-mode: multiply; */

  & .back-to-top{
    position: absolute;
    right: 20px;
    bottom: 80px;
    font-family: $syrios-garamond-premier-pro-caption;
    font-weight: $syrios-font-weight-medium;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 2px;
    border: none;
    background-color: rgba(255, 255, 255, 0);
    color: #7899A8;

    &:hover{
      color: #183848
    }
  }
}
.back-to-top-icon{
  font-family: $syrios-font-family-syrios-entypo-subset;
  color: #FFFFFF;
  background-color:  #D8A048;
  border-radius: 50%;

  &:hover{
    background-color: #A86818;
  }
}
.blande-image img:first-child {
  position: absolute;
  mix-blend-mode: multiply;
}



/**** Interactive_Frame1 ****/
.sclae_front{
  height: 15vmax;
  display: block;
  opacity: 1;
  transition: 0.3s;
  position: relative;
}
.scale_back{
  height: 7.5vmax;
  display: none;
  opacity: 0;
  transition: 0.3s;
  position: relative;
  top: 2.5vmax;
}
.sclae_front_text{
  display: block;
  opacity: 1;
  transition: 0.3s;
}
.scale_back_text{
  display: none;
  opacity: 0;
  transition: 0.3s;
  position: relative;
  top: 2.5vmax;
  
}
.row-scare-content{
  position: relative;
  height: 10vmax;
  bottom: 10vmax;
  z-index: 99;
}
.row-light-blue-background {
  background-color: rgba(184,204,216,0.5);
  border-radius: 15px;
  position: relative;
  height: 10vmax;
  top: 2.5vmax;
  margin: 0% 5% !important;
}

.fade-front{
  display: block;
  opacity: 1;
  transition: 0.3s;
}
.fade-back{
  display: none;
  opacity: 0;
  transition: 0.3s;
}
.fade-2button-row{
  position: relative;
  bottom: 1.5625vmax;

}
.fade-4button-row{
  position: relative;
  bottom: 24vmax;
}

.interactive-frame {
  display: none;
  opacity: 0;
  transition: 0.3s;
}
.interactive-frame-active {
  display: block;
  opacity: 1;
  transition: 0.3s;
}
#interactive-frame1 {
  width: 180px;
}
