
$footerHeight:70px;

#glossary-page{
    -webkit-overflow-scrolling: touch;
	padding: 5% 10%;
	min-height: 95vh;

    .search-box {
        display: flex;
        font-size: $syrios-font-size-20;
        width: 30%;
        margin-left: 35%;
        height: 3em;
    
        /* Border */
        background: #fcfcfc;
        // border: 2px solid #aaa;
        border-radius: 5px;
        box-shadow: 0 0 3px #ccc, 0 10px 15px #ebebeb inset;

        input{
            color: #737271;
            font-family: $syrios-garamond-premier-pro;
            font-size: $syrios-font-size-20;
            text-indent: $syrios-font-size-24

        }
        .search-box__input {
            border-color: transparent;
            flex: 1;
        }
    }

    // .search-box{
    //     display: flex;
    //     // flex-direction: row-reverse;
    //     border: 1px solid #d1d5db;
    //     .search-box__input {
    //         border-color: transparent;
    //         flex: 1;
    //     }
    // }
    .result-list{
        
        width: 30%;
        margin-left: 35%;
        height: 0em;

    }
    .result-list-item{
        font-size: $syrios-font-size-20;
        z-index: 100;

    }
    button{
        border: none;
        cursor: pointer;
        display: inline-block;
        background-color: #FFFFFF;
        align-items: center;

        font-family: $syrios-garamond-premier-pro;
        font-style: $syrios-font-style-italic;
        font-weight: $syrios-font-weight-regular;
        font-size: $syrios-font-size-28;
        line-height: $syrios-line-spacing-30;
        letter-spacing: $syrios-character-spacing-0;
        color: $syrios-color-78a878;
      }

    .nav-tabs{
        .nav-item {
            a{
                background-color: #f8e8b8;
                display: flex;
                justify-content: center;
                clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
                width: 6.5vmax;
                color:#737271;
                font-size:$syrios-font-size-20;
                &:hover{
                    background-color: white;
                }  
            }
        }
    }
        

    
    // #glossary-tabs-tab-home{
    //     // background-color: #F0E0C0;
    
    //     /* border-bottom: 50px solid #F0E0C0;
    //     border-left: 25px solid transparent;
    //     border-right: 25px solid transparent;
    //     height: 0;
    //     width: 125px; */
    // }
    
    // #glossary-tabs-tab-home:hover, #glossary-tabs-tab-home.active{
    //     background-color: #FFFF;
    
    //     /* border-bottom: 50px solid #F0E0C0;
    //     border-left: 25px solid transparent #F0E0C0;
    //     border-right: 25px solid transparent #F0E0C0;
    //     height: 0;
    //     width: 125px; */
    // }
}

#glossary-loading{
    position:fixed;

	top: 11vmax;
	left: 0px;
	width: 100%;
	height: 100%;

	z-index: -100;
	background-image:url("../../../assets/background.jpg");

    .loader {
        position: absolute;
        margin: auto;
        left: 50%;
        top: 50%;
        -ms-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
    
      border: 0.833vmax solid #f3f3f3; /* Light grey */
      border-top: 0.833vmax solid #3498db; /* Blue */
      border-radius: 50%;
      width: 5.5vmax;
      height: 5.5vmax;
      animation: spin 2s linear infinite;
    }
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
      
}

// .glossary-modal{
//     font-family: $syrios-garamond-premier-pro;
//     font-style: $syrios-font-style-normal;
//     font-weight: $syrios-font-weight-regular;
//     font-size: 20px;

//     .glossary-modal-term{
//         font-size: 36px;
//         font-weight: bold;
//         color:#183848
//     }
//     .glossary-term-basic{
//         font-size: 18px;
//         font-weight: bold;
//         color:#183848
//     }
//     .glossary-term-title{
//         color: #a85828;
//     }

// }
#glossary-group{
    margin-bottom: 7.5vmax;

    .glossary-alphabet{
        font-family: $syrios-garamond-premier-pro;
        font-style: $syrios-font-style-normal;
        font-weight: $syrios-font-weight-bold;
        font-size: $syrios-font-size-40;
        line-height: $syrios-line-spacing-30;
        color: $syrios-color-486678;
    }
    .glossary-term{
        a{
            font-family: $syrios-garamond-premier-pro;
            // font-style: $syrios-font-style-italic;
            font-weight: $syrios-font-weight-regular;
            font-size: $syrios-font-size-28;
            line-height: $syrios-line-spacing-30;
            color: #78a878;
            & :hover{
                color: #487848;
                font-weight: bold;
            }
        }
    }

}

#glossary-term{
    background-color: white;
    margin-bottom: 70px;

    padding-top: 70px;
    padding-bottom: 70px;
    padding-left: 25%;
    padding-right: 25%;

    .glossary-item{
        margin-bottom: 2em;
    }
        .glossary-term{
        font-size: $syrios-font-size-48;
        font-weight: bold;
        color:#486678;
    }
    .glossary-basic{
        font-size: $syrios-font-size-24;
        color:#737271;
    }
    .glossary-title{
        color: #b85828;
        font-size: $syrios-font-size-32;
    }
    .glossary-body{
        color:#737271;
        font-size: $syrios-font-size-24;
    }
    .glossary-related-word{
        a{
            color: #78a878;
            font-size: $syrios-font-size-28;
        }

    }
}