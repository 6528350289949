.coin-card{
    width: 25%;
    display: flex;
    flex-direction: column;
    gap: 2%;
    margin-bottom: 50px;
    padding: 0% 1%;
    background: rgba(237, 237, 237, 0.6);
    transition: 0.3s;

    &:hover{
        background: #696969;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

        h1,h2{
            color: #D8D6D4;
        }
    }

	.image{
        width: 100%;
        height: 400px;
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: center;

        img{
            width: 100%;
            object-fit:cover;
            position: absolute;
            padding: 10% 10%;

          }
    }
    h1{
        font-weight: 600;
        font-size: 40px;
        line-height: 40px;
        text-align: center;
        color: #4A4A4A;
    }
    h2{
        font-weight: 700;
        font-size: 30px;
        line-height: 36px;
        text-align: center;
        color: #4A4A4A;
    }

}
.sportlight-card{
    width: 90%;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;

    transition: 0.3s;
    .link{
        .image{
            height: 320px;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            background: rgba(237, 237, 237, 0.6);
            &:hover{
                background: #696969;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    
            }
            img{
                width: 100%;
                object-fit: contain;
                padding: 10% 10%;
    
              }
        }
        h1{
            font-weight: 600;
            font-size: 32px;
            text-align: center;
            color: #4A4A4A;
        }
        h2{
            font-weight: 700;
            font-size: 24px;
            text-align: center;
            color: #4A4A4A;
            margin-bottom: 20px !important ;
        }
    }
}