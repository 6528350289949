#coin-catalog{
    -webkit-overflow-scrolling: touch;
	height: 100%;
	padding:5% 10%;
	scroll-behavior: smooth;

	.catalog-section{
		// margin-bottom: 100px;
		text-align: center;

		.anchor{
			display: block;
			// height: 7.5vh; /*same height as header*/
			margin-top: 7.5vh; /*same height as header*/
			visibility: hidden;
		}

		h2{
			margin-bottom: 50px;
		}
		p{
			font-style: normal;
			font-weight: 500;
			font-size: 32px;
			line-height: 51px;
			text-align: center;

			color: #7899A8;
		}
		.catalog-buttons{
			display: flex;
			justify-content: space-around;
            align-items: center;
			margin-bottom: 7.5vh;

			a{
				padding: 0.5em 0.75em;
				border-radius: 15px;
		
				font-weight: 700;
				font-size: 3rem;
				line-height: 36px;
				text-align: center;
		
				// color: #F4F2F0;
				background: #ffffff 0% 0% no-repeat padding-box;
				border: 2px solid #7899a8;
				opacity: 1;
				transition: 0.5s;
			  
				font-family: $syrios-garamond-premier-pro-caption;
				font-style: $syrios-font-style-normal;
				color: $syrios-color-7899a8;
			  
				&:hover {
				  color: #ffffff;
				  background: #7899a8 0% 0% no-repeat padding-box;
				}
			}
		}
		.spotLight{
			padding: 0% 5%;
		}
		.coins-of-the-day{
			display: flex;
			
            justify-content: space-around;
			gap: 50px;
			.left{
				flex: 4;
				display: flex;
				flex-direction: column;

				h1 {
					font-style: italic;
					font-weight: 700;
					font-size: 44px;
					line-height: 53px;
					text-align: left;

					color: #313029;
				}
				h2{
					font-style: italic;
					font-weight: 600;
					font-size: 38px;
					line-height: 46px;
					text-align: left;
					color: #7899A8;
				}
				p{
					font-style: normal;
					font-weight: 500;
					font-size: 34px;
					line-height: 41px;
					text-align: left;

					color: #7899A8;
				}
				button{
					margin-top: 30px;
					padding: 0.25em 0.75em;
					border-radius: 30px 0px;
					font-style: normal;
					font-weight: 700;
					font-size: 36px;
					line-height: 44px;

					background: #ffffff 0% 0% no-repeat padding-box;
					border: 2px solid #7899a8;
					opacity: 1;
					transition: 0.5s;

					width: fit-content;
				
					color: $syrios-color-7899a8;
				
					&:hover {
						color: #ffffff;
						background: #7899a8 0% 0% no-repeat padding-box;
					}
					a {
						color: $syrios-color-7899a8;
						&:hover {
							color: #ffffff;
						}
					}
				}
			}
			.right{
				flex: 2;
				display: flex;
                justify-content: center;
				align-items: center;

				img{
					width: 100%;
				}
			}
		}
		.catalog-VideoBox{
			height: 600px;
			align-items: center;
			display: flex;
			justify-content: center;
		}
		.logos{
			display: flex;
			justify-content: space-around;
			align-items: center;
		}
	}
		
}

.slick-next:before {
	font-family: "syrios-icon";
	font-style: normal;
	font-weight: 600;
	content: '\e806';
    font-size: 6rem;
    line-height: 1;
    opacity: 1;
    color: black;
	padding: 4rem 2rem;
}
.slick-prev:before{
	font-family: "syrios-icon";
	font-style: normal;
	font-weight: 600;
	content: '\e805';
    font-size: 6rem;
    line-height: 1;
    opacity: 1;
    color: black;
	padding: 4rem 1rem;
}



