.icon-size-xsmall {
  font-size: syrios-icon-size(xsmall) !important;
}

.icon-size-small {
  font-size: syrios-icon-size(small) !important;
}

.icon-size-medium {
  font-size: syrios-icon-size(medium) !important;
}

.icon-size-large {
  font-size: syrios-icon-size(large) !important;
}

.icon-size-xlarge {
  font-size: syrios-icon-size(xlarge) !important;
}

.icon-size-xxlarge {
  font-size: syrios-icon-size(xxlarge) !important;
}
