#coin-sort-drag-coin-box-outer-div {
  position: relative;
  margin-top: 2em;
  pointer-events: none;
  display: flex;
  justify-content: center;
}

// ********************** DRAG COIN ON ME ********************** //
#coin-sort-drag-box-full {
  width: 28%;
  height: 20vh;

  display: flex;
  justify-content: center;
}

.coin-sort-drag-coin-box {
  pointer-events: auto;
  border: 2px dashed rgb(119, 153, 168);
  background-color: rgba(119, 153, 168, 0.36);
  width: 100%;
  height: 100%;

  color: #7899A8;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.coin-sort-drag-coin-box:hover {
  background-color: rgba(119, 153, 168, 0.74);
  color: white;

  pointer-events: none;
}

.coin-sort-drag-coin-box-text {
  font-family: $syrios-garamond-premier-pro;
  font-style: $syrios-font-style-normal;
  font-weight: $syrios-font-weight-regular;
  font-size: 1.4em;

  text-align: center;
}

.coin-sort-drag-coin-box-hover-element {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.coin-sort-drag-box-in-coin-grid {
  width: 100%;
  height: 100%;
  font-size: .7em;
}

// ********************** GRID ARRANGMENT CSS ********************** //
#coin-sort-grid-arrangement-wrapper {
  pointer-events: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.coin-sort-grid-cell-flip-box {
  cursor: pointer;
}

#coin-sort-grid-6x3-arrangement {
  width: 800px;
  max-width: 85%;
  aspect-ratio: 16 / 9;

  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px;
}
#coin-sort-grid-6x3-cell-1 { grid-area: 1 / 1 / 2 / 2; }
#coin-sort-grid-6x3-cell-2 { grid-area: 1 / 2 / 2 / 3; }
#coin-sort-grid-6x3-cell-3 { grid-area: 1 / 3 / 2 / 4; }
#coin-sort-grid-6x3-cell-4 { grid-area: 1 / 4 / 2 / 5; }
#coin-sort-grid-6x3-cell-5 { grid-area: 1 / 5 / 2 / 6; }
#coin-sort-grid-6x3-cell-6 { grid-area: 1 / 6 / 2 / 7; }
#coin-sort-grid-6x3-cell-7 { grid-area: 2 / 1 / 3 / 2; }
#coin-sort-grid-6x3-cell-8 { grid-area: 2 / 2 / 3 / 3; }
#coin-sort-grid-6x3-cell-9 { grid-area: 2 / 3 / 3 / 4; }
#coin-sort-grid-6x3-cell-10 { grid-area: 2 / 4 / 3 / 5; }
#coin-sort-grid-6x3-cell-11 { grid-area: 2 / 5 / 3 / 6; }
#coin-sort-grid-6x3-cell-12 { grid-area: 2 / 6 / 3 / 7; }
#coin-sort-grid-6x3-cell-13 { grid-area: 3 / 1 / 4 / 2; }
#coin-sort-grid-6x3-cell-14 { grid-area: 3 / 2 / 4 / 3; }
#coin-sort-grid-6x3-cell-15 { grid-area: 3 / 3 / 4 / 4; }
#coin-sort-grid-6x3-cell-16 { grid-area: 3 / 4 / 4 / 5; }
#coin-sort-grid-6x3-cell-17 { grid-area: 3 / 5 / 4 / 6; }
#coin-sort-grid-6x3-cell-18 { grid-area: 3 / 6 / 4 / 7; } 

#coin-sort-grid-6x3-arrangement div div.coin-sort-grid-cell-icons-div {
  font-size: 1em !important;                                                                  
}                                                                                              
                                                                                               

#coin-sort-grid-6x3-arrangement div div.coin-sort-grid-cell-icons-div i.coin-info-icon-x {
  position: absolute;

  top: 0 !important;
  right: -5px !important;
}

#coin-sort-grid-6x3-arrangement div div.coin-sort-grid-cell-icons-div i.coin-info-icon-rotate {
  bottom: -5px !important;                                                                     
  right: -5px !important;                                                                      
}                                                                                              
                                                                                               
#coin-sort-grid-6x3-arrangement div div.coin-sort-grid-cell-icons-div i.coin-info-scale-icon {
  bottom: -5px !important;                                                                     
  left: -20px !important;                                                                      
}                                                                                              
                                                                                               
#coin-sort-grid-6x3-arrangement div div.coin-sort-grid-cell-icons-div i {
  font-size: 2em !important;
}

#coin-sort-grid-3x2-arrangement {
  width: 800px;
  max-width: 85%;
  aspect-ratio: 16 / 9;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px;
}

#coin-sort-grid-3x2-cell-1 { grid-area: 1 / 1 / 2 / 2; }
#coin-sort-grid-3x2-cell-2 { grid-area: 1 / 2 / 2 / 3; }
#coin-sort-grid-3x2-cell-3 { grid-area: 1 / 3 / 2 / 4; }
#coin-sort-grid-3x2-cell-4 { grid-area: 2 / 1 / 3 / 2; }
#coin-sort-grid-3x2-cell-5 { grid-area: 2 / 2 / 3 / 3; }
#coin-sort-grid-3x2-cell-6 { grid-area: 2 / 3 / 3 / 4; }

#coin-sort-grid-3x2-arrangement div div.coin-sort-grid-cell-icons-div {
  font-size: 1.375em !important;                                                                  
}                                                                                              

#coin-sort-grid-3x2-arrangement div div.coin-sort-grid-cell-icons-div i.coin-info-icon-x {
  position: absolute;

  top: 0 !important;
  right: 10px !important;
}
                                                                                               
#coin-sort-grid-3x2-arrangement div div.coin-sort-grid-cell-icons-div i.coin-info-icon-rotate {
  bottom: -5px !important;                                                                     
  right: 10px !important;                                                                      
}                                                                                              
                                                                                               
#coin-sort-grid-3x2-arrangement div div.coin-sort-grid-cell-icons-div i.coin-info-scale-icon {
  bottom: -5px !important;                                                                     
  left: -5px !important;                                                                       
}                                                                                              
                                                                                               
#coin-sort-grid-3x2-arrangement div div.coin-sort-grid-cell-icons-div i {
  font-size: 2.2em !important;
}

#coin-sort-grid-2x2-arrangement {
  width: 800px;
  max-width: 85%;
  aspect-ratio: 16 / 9;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px;
}

.coin-sort-grid-2x2-cell-1 { grid-area: 1 / 1 / 2 / 2; }
.coin-sort-grid-2x2-cell-2 { grid-area: 1 / 2 / 2 / 3; }
.coin-sort-grid-2x2-cell-3 { grid-area: 2 / 1 / 3 / 2; }
.coin-sort-grid-2x2-cell-4 { grid-area: 2 / 2 / 3 / 3; } 

#coin-sort-grid-2x2-arrangement div div.coin-sort-grid-cell-icons-div {
  font-size: 1.375em !important;                                                                  
}                                                                                              

#coin-sort-grid-2x2-arrangement div div.coin-sort-grid-cell-icons-div i.coin-info-icon-x {
  position: absolute;

  top: 0 !important;
  right: 80px !important;
}
                                                                                               
#coin-sort-grid-2x2-arrangement div div.coin-sort-grid-cell-icons-div i.coin-info-icon-rotate {
  bottom: -5px !important;                                                                     
  right: 80px !important;                                                                      
}                                                                                              
                                                                                               
#coin-sort-grid-2x2-arrangement div div.coin-sort-grid-cell-icons-div i.coin-info-scale-icon {
  bottom: -5px !important;                                                                     
  left: 60px !important;                                                                       
}                                                                                              
                                                                                               
#coin-sort-grid-2x2-arrangement div div.coin-sort-grid-cell-icons-div i {
  font-size: 2.2em !important;
}

#coin-sort-grid-3x1-arrangement {
  width: 800px;
  max-width: 85%;
  aspect-ratio: 16 / 5;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
}

#coin-sort-grid-3x1-cell-1 { grid-area: 1 / 1 / 2 / 2; }
#coin-sort-grid-3x1-cell-2 { grid-area: 1 / 2 / 2 / 3; }
#coin-sort-grid-3x1-cell-3 { grid-area: 1 / 3 / 2 / 4; }

#coin-sort-grid-3x1-arrangement div div.coin-sort-grid-cell-icons-div {
  font-size: 1.375em !important;                                                                  
}                                                                                              

#coin-sort-grid-3x1-arrangement div div.coin-sort-grid-cell-icons-div i.coin-info-icon-x {
  position: absolute;

  top: 0 !important;
  right: 20px !important;
}
                                                                                               
#coin-sort-grid-3x1-arrangement div div.coin-sort-grid-cell-icons-div i.coin-info-icon-rotate {
  bottom: -5px !important;                                                                     
  right: 20px !important;                                                                      
}                                                                                              
                                                                                               
#coin-sort-grid-3x1-arrangement div div.coin-sort-grid-cell-icons-div i.coin-info-scale-icon {
  bottom: -5px !important;                                                                     
  left: 0px !important;                                                                        
}                                                                                              
                                                                                               
#coin-sort-grid-3x1-arrangement div div.coin-sort-grid-cell-icons-div i {
  font-size: 2.5em !important;
}

#coin-sort-grid-2x1-arrangement {
  width: 800px;
  max-width: 85%;
  aspect-ratio: 16 / 9;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 3fr;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
}

#coin-sort-grid-2x1-cell-1 { grid-area: 1 / 1 / 2 / 2; }
#coin-sort-grid-2x1-cell-2 { grid-area: 1 / 2 / 2 / 3; } 

#coin-sort-grid-2x1-arrangement div div.coin-sort-grid-cell-icons-div {
  font-size: 1.5em !important;                                                                  
}

#coin-sort-grid-2x1-arrangement div div.coin-sort-grid-cell-icons-div i.coin-info-icon-x {
  position: absolute;

  top: 0 !important;
  right: 40px !important;
}

#coin-sort-grid-2x1-arrangement div div.coin-sort-grid-cell-icons-div i.coin-info-icon-rotate {
  bottom: -5px !important;                                                                     
  right: 40px !important;                                                                      
}                                                                                              
                                                                                               
#coin-sort-grid-2x1-arrangement div div.coin-sort-grid-cell-icons-div i.coin-info-scale-icon {
  bottom: -5px !important;                                                                     
  left: 30px !important;                                                                       
}                                                                                              
                                                                                               
#coin-sort-grid-2x1-arrangement div div.coin-sort-grid-cell-icons-div i {
  font-size: 2.5em !important;
}

#coin-sort-grid-1x1-arrangement {
  width: 800px;
  max-width: 85%;
  aspect-ratio: 16 / 11;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3fr;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
}

#coins-sort-grid-1x1-cell-1 { grid-area: 1 / 1 / 2 / 2; }

#coin-sort-grid-1x1-arrangement div div.coin-sort-grid-cell-icons-div {
  font-size: 2.188em !important;
}
#coin-sort-grid-1x1-arrangement div div.coin-sort-grid-cell-icons-div i.coin-info-icon-rotate {
  bottom: 40px !important;
  right: 120px !important;
}

#coin-sort-grid-1x1-arrangement div div.coin-sort-grid-cell-icons-div i.coin-info-scale-icon {
  bottom: 40px !important;
  left: 100px !important;
}

#coin-sort-grid-1x1-arrangement div div.coin-sort-grid-cell-icons-div i {
  font-size: 2em !important;
}

.coin-info-icon-x {
  color: #7899A8;
  transition: 0.3s;
  cursor: pointer;
}

.coin-info-icon-x:hover {
  color: #183848;
}
