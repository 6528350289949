
#landing-page {
	-webkit-overflow-scrolling: touch;
	padding: 5% 10%;
	height: 100%;

	// background: url(src/assets/pages/LandingPageAssets/Full-Screen-BG-Home-Map.jpg);
	background:url('../assets/pages/LandingPageAssets/Full-Screen-BG-Home-Map.jpg')
}

.landing-green-paragraph{
	color:#184818;
	font-weight:600;
	width:100%;
	padding:2%;
	padding-left: 25%;
	padding-right: 25%;
	margin-bottom: 2%;
	background: rgba(216, 214, 212,0.3);
}

#landing-video {
	background: white;
	// padding: 1em;
	box-shadow: 0px 0px 20px #24201659;
	// width: 60%;
	// width: 48.4375vmax;
	// height: 25vmax;

}

.landing-button-size {
	position: relative;
	// top: 0px;
	// left: 0px;
	height: 12.5vmax;
	width: 12.5vmax;
	// padding: 1em;
	// margin: 1.25em;
}

.landing-button-img {
	position: relative;
	// top: 7%;
	// margin: .4em 1.1em .4em 1.2em;
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position:center
	
}

.on-hover-dim {
	display: flex;
	justify-content: center; /* align horizontal */
	align-items: center; /* align vertical */
	width: 100%;
	height: 100%;
	transition: 0.3s;
	background: rgba(0,0,0,0);
	&:hover{
		background: rgba(0,0,0,0.5);
	}
}
