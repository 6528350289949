
.coin-info-url {
  color: #173847 !important;
  transition: 0.3s;
}

.coin-info-url:hover {
  color: #486678 !important;
}

.coin-info-x-icon {
  font-size: 50px;
  color: #7899A8;
  cursor: pointer;

  position: absolute;
  top: 0px;
  right: -10px;
  z-index: 1000;

  transition: 0.3s;
}

.coin-info-x-icon:hover {
  font-size: 50px;
  color: #183848;
  cursor: pointer;

  position: absolute;
  top: 0px;
  right: -10px;
  z-index: 1000;
}

.coin-info {
  width: 50vmax;
  max-height: 70vh;
  overflow-y: scroll;
  // width: 80vw;
  // display: grid;
  // grid-template-columns: 1fr 2fr;
  // grid-template-rows: 1fr 2fr 5fr 1fr;
  
}
.coin-info-title{
  font-family: $syrios-garamond-premier-pro;
  font-size: $syrios-font-size-36 ;
  font-style: $syrios-font-style-normal;
  font-weight: $syrios-font-weight-600;
  line-height: $syrios-line-spacing-30;
  color: $syrios-color-183848;
}
.coin-info-subtitle{
  font-family: $syrios-garamond-premier-pro;
  font-size: $syrios-font-size-28 ;
  font-style: $syrios-font-style-normal;
  font-weight: $syrios-font-weight-600;
  line-height: $syrios-line-spacing-30;
  color: $syrios-color-183848;
}
.coin-info-basic{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.coin-info-detail{
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.coin-info-basic-text{
  font-family: $syrios-garamond-premier-pro;
  font-style: $syrios-font-style-normal;
  font-weight: $syrios-font-weight-400;
  font-size: $syrios-font-size-20;
  line-height: $syrios-line-spacing-20;
  color: $syrios-color-737271;
}
.coin-info-basic-content{
  font-family: $syrios-garamond-premier-pro;
  font-style: $syrios-font-style-normal;
  font-weight: $syrios-font-weight-600;
  font-size: $syrios-font-size-20;
  line-height: $syrios-line-spacing-20;
  color: $syrios-color-183848;
}

.coin-info-detail-text{
  font-family: $syrios-garamond-premier-pro;
  font-style: $syrios-font-style-normal;
  font-weight: $syrios-font-weight-400;
  font-size: $syrios-font-size-22;
  line-height: $syrios-line-spacing-20;
  color: $syrios-color-737271;
}
.coin-info-detail-content{
  font-family: $syrios-garamond-premier-pro;
  font-style: $syrios-font-style-normal;
  font-weight: $syrios-font-weight-600;
  font-size: $syrios-font-size-22;
  line-height: $syrios-line-spacing-20;
  color: $syrios-color-183848;
}

.coin-info-reference-text{
  font-family: $syrios-garamond-premier-pro;
  font-style: $syrios-font-style-normal;
  font-weight: $syrios-font-weight-400;
  font-size: $syrios-font-size-18;
  line-height: $syrios-line-spacing-20;
  color: $syrios-color-737271;
}
.coin-info-reference-content{
  font-family: $syrios-garamond-premier-pro;
  font-style: $syrios-font-style-normal;
  font-weight: $syrios-font-weight-600;
  font-size: $syrios-font-size-16;
  line-height: $syrios-line-spacing-20;
  color: $syrios-color-183848;
}





/*** COIN INFO IMAGE SECTION ***/
.coin-info-image {
  height: 20vmax;
  display: grid;
  grid-area: 1 / 1 / 2 / 2;
}

.coin-image-box {
  position: relative;
  width: 100%;
  height: 100%;
}

.coin-info-icon-rotate {
  position: absolute;
  bottom: 10%;
  right: 10%;
  // margin: 15px;

  color: #7899A8;
  font-size: $syrios-font-size-48;
  cursor: pointer;
  transition: 0.3s;
}

.coin-info-icon-rotate:hover {
  color: #183848;
}

.coin-info-scale-icon {
  position: absolute;
  bottom: 10%;
  left: 10%;
  // margin: 15px;

  color: #7899A8;
  font-size: $syrios-font-size-48;
  cursor: pointer;
  transition: 0.3s;
}

.coin-info-scale-icon:hover {
  color: #183848;
}

:root {
  --coin-info-coin-image-translate-x: -50%;
  --coin-info-coin-image-translate-y: -50%;
}

.coin-info-dotted-circle {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(var(--coin-info-coin-image-translate-x), var(--coin-info-coin-image-translate-y));
  height: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  transition: 0.5s;
}

.coin-info-dotted-circle:before {
  position: absolute;
  content: '';
  height: calc(100% + 10px); 
  width: calc(100% + 10px); 
  border: 1px dashed black;
  top: -13px;
  left: -13px;
  border-radius: inherit;
}

.coin-info-image-flip {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(var(--coin-info-coin-image-translate-x), var(--coin-info-coin-image-translate-y));
  max-width: 100%;
  max-height: 70%;
  transition: 0.5s;
}

.coin-info-image-diameter-box {
  margin: 0;
  position: absolute;
  top: 78%;
  left: 48%;
  transform: translate(-50%, -50%);

  transition: 0.5s;
}

.coin-info-no-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/*** COIN INFO DETAILS SECTION ***/
// .coin-info-details-background {
//   grid-area: 1 / 2 / 2 / 3; 
// }

// .coin-info-details {
//   margin: 10px;

//   display: grid;
//   grid-template-columns: repeat(3, 1fr);
//   grid-template-rows: repeat(7, 1fr);
//   grid-column-gap: 0px;
//   grid-row-gap: 0px; 
// }

// .coin-info-small-gray-text {
//   font-size: 0.8em;
// }

// .coin-info-title { grid-area: 1 / 1 / 2 / 4; }
// /* column 1 */
// .coin-info-ancient-territory { grid-area: 2 / 1 / 3 / 2; }
// .coin-info-modern-country { grid-area: 3 / 1 / 4 / 2; }
// .coin-info-issuing-authority { grid-area: 4 / 1 / 5 / 2; }
// .coin-info-governing-power { grid-area: 5 / 1 / 6 / 2; }
// .coin-info-language { grid-area: 6 / 1 / 7 / 2; }
// /* column 2 */
// .coin-info-mint { grid-area: 2 / 2 / 3 / 3; }
// .coin-info-mint-modern-name { grid-area: 3 / 2 / 4 / 3; }
// .coin-info-material { grid-area: 4 / 2 / 5 / 3; }
// .coin-info-denomination { grid-area: 5 / 2 / 6 / 3; }
// .coin-info-diameter { grid-area: 6 / 2 / 7 / 3; }
// /* column 3 */
// .coin-info-date-range { grid-area: 2 / 3 / 3 / 4; }
// .coin-info-latitude { grid-area: 3 / 3 / 4 / 4; }
// .coin-info-longitude { grid-area: 4 / 3 / 5 / 4; }
// .coin-info-categories { grid-area: 5 / 3 / 7 / 4; } 

// /*** COIN INFO TYPES SECTION ***/
// .coin-info-types {
//   grid-area: 2 / 1 / 3 / 2;
//   display: grid;
//   grid-template-columns: 1fr;
//   grid-template-rows: repeat(4, 1fr);
//   grid-column-gap: 0px;
//   grid-row-gap: 0px;
// }

// .coin-info-obverse-type { grid-area: 1 / 1 / 2 / 2; }
// .coin-info-obverse-legend { grid-area: 2 / 1 / 3 / 2; }
// .coin-info-reverse-type { grid-area: 3 / 1 / 4 / 2; }
// .coin-info-reverse-legend { grid-area: 4 / 1 / 5 / 2; }

// /*** COIN INFO SOURCE MATERIALS AND REFERENCES SECTION ***/
// .coin-info-source-material-background {
//   background-color: rgba(240, 224, 192, 0.5);
//   grid-area: 2 / 2 / 3 / 3;
// }

// .coin-info-source-material {
//   margin: 10px;

//   display: grid;
//   grid-template-columns: repeat(2, 1fr);
//   grid-template-rows: repeat(4, 1fr);
//   grid-column-gap: 0px;
//   grid-row-gap: 0px;
// }

// .coin-info-source-image { grid-area: 1 / 1 / 2 / 2; }
// .coin-info-rights-holder { grid-area: 2 / 1 / 3 / 2; }
// .coin-info-wikidata { grid-area: 3 / 1 / 4 / 2; }
// .coin-info-mint-nomisma-uri { grid-area: 4 / 1 / 5 / 2; }
// .coin-info-obverse-nomisma-uri { grid-area: 1 / 2 / 2 / 3; }
// .coin-info-reverse-nomisma-uri { grid-area: 2 / 2 / 3 / 3; }
// .coin-info-ref-1 { grid-area: 3 / 2 / 4 / 3; }
// .coin-info-ref-2 { grid-area: 4 / 2 / 5 / 3; }
