#error-page{
    -webkit-overflow-scrolling: touch;
    width: 100%;
    height: 95vh;

    align-items: center;
    display: flex;
    justify-content: center;
}    

