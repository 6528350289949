.flip-box {
  background-color: transparent;
  width: 90%;
  height: 90%;
  perspective: 100000000px;
}

.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* this is just an example of how the flip-box works. Great for debugging
.flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
} /* */

.flip-box-front, .flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  --webkit-transform: translate3d(0,0,0);
  --webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-box-front {
  max-height: 100%;
}

.flip-box-back {
  max-height: 100%;
  transform: rotateY(180deg);
}
