.info{
    // h2{
    //     // color: #D8B848;
    // }
    .sub-info{
        display: flex;
        justify-content: space-between;
        align-items: center;

        span{
            font-size: 32px;
            color: #999;
            cursor: pointer;
        }
        .search{
            display: flex;
            align-items: center;
            font-size: 24px;
            background-color: white;
            border-radius: 15px;
            padding: 5px;
            width: 50%;

            span{
                font-size: 24px;
                // color: #999;
            }

            input{
                width: 100%;
                border: none;
                outline: none;
                
            }
        }
    }
}