@import '../0-base/_base-dir.scss';
@import '../1-themes/_themes-dir.scss';
@import '../2-foundations/_foundations-dir.scss';


.story-end-frame-button {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #7899a8;
  border-radius: 30px;
  opacity: 1;
  width: 20vmax;
  height: 3vmax;
  transition: 0.5s;

  font-family: $syrios-garamond-premier-pro-caption;
  font-style: $syrios-font-style-normal;
  font-weight: $syrios-font-weight-medium;
  font-size: $syrios-font-size-30;
  line-height: $syrios-line-spacing-45;
  letter-spacing: $syrios-character-spacing-0;
  color: $syrios-color-7899a8;

  &:hover {
    color: #ffffff;
    background: #7899a8 0% 0% no-repeat padding-box;
  }
}


.contact-us-button{
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #7899a8;
  border-radius: 20px;
  opacity: 1;
  width: 10vmax;
  height: 3.125vmax;
  transition: 0.5s;

  font-family: $syrios-garamond-premier-pro-caption;
  font-style: $syrios-font-style-normal;
  font-weight: $syrios-font-weight-medium;
  font-size: $syrios-font-size-24;
  line-height: $syrios-line-spacing-25;
  letter-spacing: $syrios-character-spacing--20;
  color: $syrios-color-7899a8;

  &:hover {
    color: #ffffff;
    background: #7899a8 0% 0% no-repeat padding-box;
  }
}

.fade-frame-button-active{
  background: #7899a8 0% 0% no-repeat padding-box;
  border: 1px solid #7899a8;
  border-radius: 15px;
  opacity: 1;
  width: 8.333vmax;
  height: 1.5625vmax;
  transition: 0.5s;

  font-family: $syrios-garamond-premier-pro-caption;
  font-style: $syrios-font-style-normal;
  font-weight: $syrios-font-weight-medium;
  font-size: $syrios-font-size-20;
  line-height: $syrios-line-spacing-25;
  letter-spacing: $syrios-character-spacing--20;
  color: #ffffff;

  &:hover{
    color: #ffffff;
    background: #7899a8 0% 0% no-repeat padding-box;
  }
}
.fade-frame-button{
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #7899a8;
  border-radius: 15px;
  opacity: 1;
  width: 8.333vmax;
  height: 1.5625vmax;
  transition: 0.5s;

  font-family: $syrios-garamond-premier-pro-caption;
  font-style: $syrios-font-style-normal;
  font-weight: $syrios-font-weight-medium;
  font-size: $syrios-font-size-20;
  line-height: $syrios-line-spacing-25;
  letter-spacing: $syrios-character-spacing--20;
  color: #7899a8;

  &:hover{
    color: #ffffff;
    background: #7899a8 0% 0% no-repeat padding-box;
  }
  &:visited{
    color: #ffffff;
    background: #7899a8 0% 0% no-repeat padding-box;
  }
}

.reference-tag{
  font-family: $syrios-garamond-premier-pro;
  background-color: transparent;
  border: none;
  color:#78a878;
  &:hover{
    color:#487848
  }
  
  & sup{
    color: #78a878;
    font-style: $syrios-font-style-normal;
    &:hover{color:#487848}
  }
}
.glossary-tag{  
    color: #78a878;
    position: relative;
    display: inline-block;
    
  &[data-title]:hover::after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;

  }
  &[data-title]:after {
    content: attr(data-title);
    background-color: rgb(0,0,0,0.8);
    color: #fff;
    position: absolute;
    padding: 1em;
    border-radius: 6px;

    font-size: $syrios-font-size-16;
    font-family: $syrios-font-family-garamond-premier-pro;
    font-style: $syrios-font-style-normal;
    font-weight: $syrios-font-weight-regular;
    line-height: $syrios-line-spacing-30;
    letter-spacing: $syrios-character-spacing--05;

    width: 20vmax; 
    top: 100%;
    left: 50%;
    margin-left: -10vmax;
    opacity: 0;
    z-index: 99999;
    visibility: hidden;
    white-space: pre-line;
  }
  [data-title] {
      position: relative;
  }
}

.external-tag{
  text-decoration:solid;

}